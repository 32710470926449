@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

/* For mobile */
.link a {
    text-decoration: none;
    font-size: 60px;
    color: #49494b;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 200;
}

.link-text {
    margin-left: 30px;
}

.navbar {
    padding-top: 40px;
    margin-right: 30px;
    background: white;
}

.logoSize {
    height: 80px;
    width: auto;
    padding: 10px;
}

.toggle-icon {
    color: black;
}

.brand {
    margin-left: 30px;
    font-size: 78px;
    font-family: 'Playfair Display', serif;
    color: #49494b;
    letter-spacing: 5px;
}

.justify-content-end {
    margin-top: 19px;
    background: white;
}

/* For desktop */
@media (min-width: 1000px) {
    .link a {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 130%;
    }

    .justify-content-end {
        margin-top: 10px;
        margin-right: unset;
    }

    .navbar {
        padding-top: unset;
        margin-right: unset;
    }
}