/* COMMON */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.page-title {
    font-family: 'Playfair Display', serif;
    text-align: center;
    font-size: 60px;
}

.arrows {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.arrows a {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
    color: #49494b;
}

.arrows a:hover {
    text-decoration: none;
}

.left-nav-icon {
    margin-right: 500px;
}

.nav-icon {
    color: #49494b;
}

/* For mobile */
@media (max-width: 1000px) {
    .page-title {
        letter-spacing: 5px;
        margin-top: 30px;
        font-size: 100px;
    }

    .left-nav-icon {
        margin-right: 400px;
    }

    .arrows a {
        font-size: 35px;
    }
}

/* HOME */
.container {
    display: flex;
    max-width: 1300px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.img {
    opacity: 0.95;
    position: relative;
    margin-top: 30px;
    width: auto;
    height: 800px;
}

.center {
    padding-top: 50px;
    justify-content: center;
}

.logo {
    font-size: 100px;
    display: block;
    text-align: center;
    margin: 0 auto;
}

.title {
    text-align: center;
    margin: 0 auto;
    font-size: 130px;
    letter-spacing: 10px;
    font-family: 'Playfair Display', serif;
    margin-top: 10px;
}

.box {
    position: relative;
    text-align: center;
}

.imgText a {
    position: absolute;
    color: white;
    text-shadow: 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    letter-spacing: 15px;
    font-size: 100px;
    align-content: center;
}

.imgText a:hover {
    text-decoration: none;
}

.imgText a:active {
    border-bottom: 1px solid;
}

/* For desktop */
@media (min-width: 1000px) {
    .imgText a {
        font-size: 50px;
    }

    .title {
        font-size: 80px;
        margin-top: unset;
    }

    .imgText a:hover {
        display: none;
    }
}

/* ABOUT */
.about-container {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
}

.about-img {
    display: flex;
    justify-content: center;
}

.about-text {
    width: 700px;
    overflow: hidden;
    margin-left: 30px;
    margin-right: 30px;
}

.about-text h2 {
    margin-top: 5%;
    font-size: 30px;
}

.about-text p {
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 15px;
}

.headshot {
    overflow: hidden;
    max-height: 520px;
    width: auto;
    margin-right: 50px;
}

/* For mobile */
@media (max-width: 1000px) {
    .headshot {
        overflow: hidden;
        max-height: unset;
        height: 100%;
        width: 100%;
        margin-right: unset;
    }

    .about-img {
        margin-bottom: 80px;
    }

    .about-text p {
        padding-bottom: 30px;
    }
}

/* CONTACT */
.contact-container {
    margin-top: 50px;
    background-color: #D3D3D3;
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
}

.contact-title {
    margin-top: 40px;
    font-family: 'Playfair Display', serif;
    font-size: 40px;
}

.contact-container h4 {
    margin-top: 11vh;
    font-size: 30px;
}

.contact-container p {
    font-size: 15px;
}

.icons-container {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 11vh;
}

.icons-group {
    background: #D3D3D3;
    border: none;
    margin-bottom: 15px;
}

.icon {
    color: black;
}

/* For mobile */
@media (max-width: 1000px) {
    .contact-title {
        font-size: 60px;
        margin-top: 50px;
    }

    .contact-container h4 {
        margin-top: 8vh;
        font-size: 40px;
    }

    .contact-container p {
        font-size: 30px;
    }

    .icons-container {
        margin-top: 8vh;
    }

    .icons-group {
        margin-bottom: 40px;
    }

    .icon {
        margin-left: 15px;
        margin-right: 15px;
    }
}

/* TRAVEL */
.travel-container {
    display: flex;
    max-width: 800px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
}

.travel-img {
    padding-top: 30px;
    padding-bottom: 1px;
    width: 100%;
    height: 100%;
}

.travel-caption {
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #49494b;
    margin-left: 10px;
}

/* For mobile */
@media (max-width: 1000px) {
    .about-text h2 {
        font-size: 60px;
    }

    .about-text p {
        font-size: 35px;
    }

    .travel-caption {
        font-size: 25px;
    }
}

/* RESUME */
.resume-container {
    margin-top: 0px;
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
}


